import { AuthType } from '@helpers/AuthClient';
import ForgotPasswordView from '@views/ForgotPasswordView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

interface LocationState {
  email?: string;
}

const ForgotPasswordPage: FC<PageProps<null, null, LocationState | null>> = ({
  location: { state },
}) => {
  return <ForgotPasswordView authType={AuthType.Web} defaultValues={{ email: state?.email }} />;
};

export default ForgotPasswordPage;
